import React, { useEffect, useState, useMemo } from "react";
import { ConfigProvider, notification, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import RouteSetup from "./router";
// import { fetchToken, onMessageListener } from "./firebase";
import { baseUrl } from "./utils";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const App = () => {
  const [deviceToken, setDeviceToken] = useState();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const hrUser = JSON.parse(localStorage.getItem("hrUser")) || {};

  useEffect(() => {
    if (
      !hrUser.hasOwnProperty("employer_subscriptions") &&
      window.location.pathname !== "/login"
    ) {
      setIsModalVisible(true);
    }
  }, []);

  const handleLogout = () => {
    setIsModalVisible(false);
    localStorage.clear();
    navigate("/login");
  };

  // fetchToken(setDeviceToken);

  // useEffect(() => {
  //   let authToken = localStorage.getItem("x-auth-token");
  //   if (authToken && deviceToken) {
  //     sendTokenToServer(deviceToken);
  //   }
  //   onMessageListener()
  //     .then((payload) => {
  //       notification.open({
  //         type: "info",
  //         duration: null,
  //         message: payload.notification.title,
  //         description: payload.notification.body,
  //         onClick: () => navigate("/notifications"),
  //       });
  //     })
  //     .catch((err) => console.log("failed: ", err));
  // }, [deviceToken, navigate]);

  // const sendTokenToServer = async (deviceToken?: any) => {
  //   let authToken = localStorage.getItem("x-auth-token");
  //   const requestData = {
  //     device_token: deviceToken,
  //   };

  //   try {
  //     const response = await fetch(`${baseUrl}/user/device-token`, {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${authToken}`,
  //       },
  //       body: JSON.stringify(requestData),
  //     });

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }

  //     const data = await response.json();
  //     return data;
  //   } catch (error) {
  //     console.log("Error:", error);
  //   }
  // };

  return (
    <>
      <Modal
        title="Major Update 🎉"
        open={isModalVisible}
        onOk={handleLogout}
        okText="Log Out"
        onCancel={null}
        closable={false}
        maskClosable={false}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div style={{ marginTop: 20 }}>
          <p>
            We've updated the app to improve your experience. Please log out and
            log back in to access the latest features.
          </p>
        </div>
      </Modal>

      <RouteSetup />
    </>
  );
};

export default App;
